// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  message:'development',
  api_heisenberg:'https://api-heisenberg.globalunbanked.com/',
  api_heisenberg_token: 'PsYbMpIinl5oVY5TAAsv5m6ukpPcJi',
  api_express: 'https://payment.oregonback.snbxt.com/',
  api_express_token: 'V33ntOZLQGKH8Nba40ORU5glQlxWqh',
  urlSucceed:'https://expresswallet.live.snbxt.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
